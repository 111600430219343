* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.app {
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
}
